import {actions} from "./actions";

const INIT_STATE = {
    width: null
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case actions.UPDATE_SCREEN_RESOLUTION:
            if (action.payload.width !== state.width) return {...state, width: action.payload.width};
            return state;
        default: return state;
    }
}
export default reducer;