import {actions} from "./actions";

const INIT_STATE = {
    total: 0,
    newestCreatedId: 0,
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case actions.SET_TOTAL_SITES:
            return state.total !== action.payload ? {...state, total: action.payload} : state;
        case actions.SET_NEWEST_CREATED_SITE_ID:
            return state.newestCreatedId !== action.payload ? {...state, newestCreatedId: action.payload} : state;
        default: return state;
    }
}
export default reducer;