export const actions = {
    SET_TOTAL_USERS: 'setTotalUsers',
    SET_NEWEST_CREATED_USER_ID: 'setNewestCreatedUserId',
}



export const setTotalUsers = (totalUsers) => {
    return {
        type: actions.SET_TOTAL_USERS,
        payload: totalUsers
    }
}

export const setNewestCreatedUserId = (id) => {
    return {
        type: actions.SET_NEWEST_CREATED_USER_ID,
        payload: id
    }
}