import _ from "lodash"
import React from "react";
import {AuthRoute, UnauthRoute} from "react-router-auth";
import {Route} from "react-router-dom";
import {connect} from "react-redux";

export const DASHBOARD = "/dashboard"
export const SITES_COLLECTION = "/sites"
export const PROCESSES_COLLECTION = "/processes"
export const DOCUMENTS_COLLECTION = "/documents"
export const ORGANIZATION = "/organization"

const ReactLazyPreload = importStatement => {
    const Component = React.lazy(importStatement);
    Component.preload = importStatement;
    return Component;
};

const UserCollection = ReactLazyPreload(() => import("../Pages/User/Collection"));
const UserResource = ReactLazyPreload(() => import("../Pages/User/Resource"));
const AssignmentCollection = ReactLazyPreload(() => import("../Pages/Assignment/Collection"));
const AssignmentResource = ReactLazyPreload(() => import("../Pages/Assignment/Resource"));
const SiteCollection = ReactLazyPreload(() => import("../Pages/Site/Collection"));
const SiteResource = ReactLazyPreload(() => import("../Pages/Site/Resource"));
const ProcessCollection = ReactLazyPreload(() => import("../Pages/Process/Collection"));
const ProcessResource = ReactLazyPreload(() => import("../Pages/Process/Resource"));
const Login = ReactLazyPreload(() => import("../Pages/Auth/Login"));
const NotFound = ReactLazyPreload(() => import("../Pages/404"));

const mapStateToProps = state => ({
    authenticated: state.auth.isAuthenticated,
})
const ConnectedAuthRouter = connect(mapStateToProps)(AuthRoute)
const ConnectedUnauthRouter = connect(mapStateToProps)(UnauthRoute)


export const namedRoutes = {
    USER_COLLECTION: {
        path: "/users/",
        exact: true,
        component: UserCollection,
        route: ConnectedAuthRouter,
        redirectTo: "/login/"
    },
    USER_RESOURCE: {
        path: "/user/:id/",
        exact: true,
        component: UserResource,
        route: ConnectedAuthRouter,
        redirectTo: "/login"
    },
    ASSIGNMENT_COLLECTION: {
        path: "/assignment/",
        exact: true,
        component: AssignmentCollection,
        route: ConnectedAuthRouter,
        redirectTo: "/login/"
    },
    ASSIGNMENT_RESOURCE: {
        path: "/assignment/:id/",
        exact: true,
        component: AssignmentResource,
        route: ConnectedAuthRouter,
        redirectTo: "/login/"
    },

    SITE_COLLECTION: {
        path: "/sites/",
        exact: true,
        component: SiteCollection,
        route: ConnectedAuthRouter,
        redirectTo: "/login/"
    },
    SITE_RESOURCE: {
        path: "/sites/:id/",
        exact: true,
        component: SiteResource,
        route: ConnectedAuthRouter,
        redirectTo: "/login/"
    },
    PROCESS_COLLECTION: {
        path: "/processes/",
        exact: true,
        component: ProcessCollection,
        route: ConnectedAuthRouter,
        redirectTo: "/login/"
    },
    PROCESS_RESOURCE: {
        path: "/process/:id/",
        exact: true,
        component: ProcessResource,
        route: ConnectedAuthRouter,
        redirectTo: "/login"
    },
    LOGIN: {
        path: "/login/",
        exact: true,
        component: Login,
        route: ConnectedUnauthRouter,
        redirectTo: "/assignments/by-site"
    },
    NOT_FOUND: { path: "*", component: NotFound, route: Route },
}

export const routes = _.values(namedRoutes)