export const actions = {
    SET_LAST_MODIFIED_USER: 'setLastModifiedUser',
}

export const setLastModifiedUser = (resource) => {
    return {
        type: actions.SET_LAST_MODIFIED_USER,
        payload: resource
    }
}
