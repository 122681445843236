import {actions} from "./actions";

const INIT_STATE = {
    totalUsers: 0,
    newestCreatedUserId: 0,
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case actions.SET_TOTAL_USERS:
            return state.totalUsers !== action.payload ? {...state, totalUsers: action.payload} : state;
        case actions.SET_NEWEST_CREATED_USER_ID:
            return state.newestCreatedUserId !== action.payload ? {...state, newestCreatedUserId: action.payload} : state;
        default: return state;
    }
}
export default reducer;