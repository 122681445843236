export const actions = {
    UPDATE_SCREEN_RESOLUTION: 'updateScreenResolution',
}

export const updateScreenResolution = (width) =>(
    {
        type: actions.UPDATE_SCREEN_RESOLUTION,
        payload: {
            width: width,
        }
    }
)