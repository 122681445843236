export const actions = {
    SET_ENABLE_MOBILE_MENU: 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU',
    SET_ENABLE_MOBILE_MENU_SMALL: 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL',
    SET_ENABLE_CLOSED_SIDEBAR: 'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR',
}

export const setEnableClosedSidebar = enableClosedSidebar => ({
    type: actions.SET_ENABLE_CLOSED_SIDEBAR,
    enableClosedSidebar
});

export const setEnableMobileMenu = enableMobileMenu => ({
    type: actions.SET_ENABLE_MOBILE_MENU,
    enableMobileMenu
});

export const setEnableMobileMenuSmall = enableMobileMenuSmall => ({
    type: actions.SET_ENABLE_MOBILE_MENU_SMALL,
    enableMobileMenuSmall
});

