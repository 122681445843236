export const actions = {
    SET_LAST_MODIFIED_PROCESS: 'setLastModifiedProcess',
}

export const setLastModifiedProcess = (resource) => {
    return {
        type: actions.SET_LAST_MODIFIED_PROCESS,
        payload: resource
    }
}
