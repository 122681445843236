import {applyMiddleware, combineReducers, createStore} from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as formReducer } from "redux-form";
import thunkMiddleware from "redux-thunk";
import logger from 'redux-logger'

import {reducer as auth} from "./Auth";
import {reducer as screen} from "./Screen";
import {reducer as theme} from "./Theme";
import {reducer as assignmentCollectionTable} from "./AssignmentCollectionTable";
import {reducer as processCollectionTable} from "./ProcessCollectionTable";
import {reducer as userCollectionTable} from "./UserCollectionTable";
import {reducer as siteCollectionTable} from "./SiteCollectionTable";
import {reducer as user} from "./User";
import {reducer as process} from "./Process";
import {reducer as site} from "./Site";
import {reducer as assignment} from "./Assignment";



const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    assignment,
    assignmentCollectionTable,
    auth,
    form: formReducer,
    processCollectionTable,
    screen,
    siteCollectionTable,
    theme,
    userCollectionTable,
    user,
    site,
    process,
});
const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
    const middlewares = [logger, thunkMiddleware];
    const store = createStore(
        persistedReducer,
        composeWithDevTools(applyMiddleware(...middlewares))
    );
    const persistor = persistStore(store)
    return { store, persistor }
}
