import history from "../../history";
import {backendV1API} from "../../../API"

export const actions = {
    LOGIN_USER: 'loginUser',
    LOGOUT_USER: 'logoutUser',
    LOGIN_FAILED: 'loginFailed',
}

const _getMe = async () => {
    const response = await backendV1API.get("/user/me/");
    return response.data;
}

export const authenticateUser = (accessToken) => async (dispatch) => {
    // TODO - Check access token
    backendV1API.defaults.headers.common['Authorization'] = `Token ${accessToken}`;
    const user = await _getMe();

    dispatch({
        type: actions.LOGIN_USER,
        payload: {
            accessToken: accessToken,
            isAuthenticated: true,
            user
        }
    });
}

export const loginUser = (credentials) => async (dispatch) => {
    let response;
    try {
        response = await backendV1API.post('/authentication/login/', credentials);
    } catch (e) {
        dispatch({
            type: actions.LOGIN_FAILED,
        });
        return;
    }
    const accessToken = response.data.token;
    if (credentials.staySignedIn) {
        localStorage.setItem('accessToken', accessToken);
    } else {
        sessionStorage.setItem('accessToken', accessToken);
    }
    backendV1API.defaults.headers.common['Authorization'] = `Token ${accessToken}`;
    const user = await _getMe();

    dispatch({
        type: actions.LOGIN_USER,
        payload: {
            accessToken: accessToken,
            isAuthenticated: true,
            user
        }
    });
    history.push("users");
}

export const logoutUser = () => async  (dispatch) => {
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('accessToken');
    backendV1API.defaults.headers.common['Authorization'] = null;

    dispatch({
        type: actions.LOGOUT_USER,
    });
    history.push("login");
}