export const actions = {
    SET_TOTAL_ASSIGNMENTS: 'setTotalAssignments',
    SET_NEWEST_CREATED_ASSIGNMENT_ID: 'setNewestCreatedAssignmentId',
}



export const setTotalAssignments = (total) => {
    return {
        type: actions.SET_TOTAL_ASSIGNMENTS,
        payload: total
    }
}

export const setNewestCreatedAssignmentId = (id) => {
    return {
        type: actions.SET_NEWEST_CREATED_ASSIGNMENT_ID,
        payload: id
    }
}