import {actions} from "./actions";

const INIT_STATE = {
    total: 0,
    lastModified: {},
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case actions.SET_LAST_MODIFIED_SITE:
            return {...state, lastModified: action.payload};
        default: return state;
    }
}
export default reducer;