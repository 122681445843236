export const actions = {
    SET_LAST_MODIFIED_ASSIGNMENT: 'setLastModifiedAssignment',
}

export const setLastModifiedAssignment = (resource) => {
    return {
        type: actions.SET_LAST_MODIFIED_ASSIGNMENT,
        payload: resource
    }
}
