import {actions} from "./actions";

const INIT_STATE = {
    enableClosedSidebar: false,
    enableMobileMenu: '',
    enableMobileMenuSmall: '',
}

export default function reducer(state = INIT_STATE, action) {
    switch (action.type) {
        case actions.SET_ENABLE_MOBILE_MENU:
            return {
                ...state,
                enableMobileMenu: action.enableMobileMenu
            };
        case actions.SET_ENABLE_MOBILE_MENU_SMALL:
            return {
                ...state,
                enableMobileMenuSmall: action.enableMobileMenuSmall
            };
        case actions.SET_ENABLE_CLOSED_SIDEBAR:
            return {
                ...state,
                enableClosedSidebar: action.enableClosedSidebar
            };
        default: return state;

    }
}