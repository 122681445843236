export const actions = {
    SET_LAST_MODIFIED_SITE: 'setLastModifiedSite',
}

export const setLastModifiedSite = (resource) => {
    return {
        type: actions.SET_LAST_MODIFIED_SITE,
        payload: resource
    }
}
