export const actions = {
    SET_TOTAL_PROCESSES: 'setTotalProcesses',
    SET_NEWEST_CREATED_PROCESS_ID: 'setNewestCreatedProcessId',
}



export const setTotalProcesses = (total) => {
    return {
        type: actions.SET_TOTAL_PROCESSES,
        payload: total
    }
}

export const setNewestCreatedProcessId = (id) => {
    return {
        type: actions.SET_NEWEST_CREATED_PROCESS_ID,
        payload: id
    }
}