import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'

import App from './App';
import * as serviceWorker from './serviceWorker';
import 'glyphicons-only-bootstrap/css/bootstrap.min.css';
import './assets/base.scss';
import configureStore from "./config/store";
import history from "./config/history";

const {store, persistor} = configureStore();

const renderApp = (Component) => {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router history={history}>
                        <Component />
                    </Router>
                </PersistGate>
            </Provider>
        </React.StrictMode>,
        document.getElementById("root")
    );
};

renderApp(App)

if (module.hot) {
    module.hot.accept("./App", () => {
        const NextApp = require("./App").default;
        renderApp(NextApp);
    });
}

serviceWorker.unregister();