import cx from "classnames";
import React, {Suspense} from 'react';
import {connect} from "react-redux";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import ResizeDetector from "react-resize-detector";

import './App.css';
import {authenticateUser} from "./config/store/Auth";
import {updateScreenResolution} from "./config/store/Screen";
import Loader from "react-loaders";
import * as routes from "config/routes"
import {backendV1API} from "./API/Backend";

class App extends React.Component {
  constructor(props) {
    super(props);
    backendV1API.defaults.headers.common['Authorization'] = `Token ${this.props.auth.accessToken}`;
  }

  handleResize = (width) => {
    this.props.updateScreenResolution(width);
    return (
        <div className={cx(
            "app-container app-theme-white fixed-header fixed-sidebar fixed-footer",
            { "closed-sidebar": this.props.enableClosedSidebar || width < 1250 },
            { "closed-sidebar-mobile": width < 1250 },
            { "sidebar-mobile-open": this.props.enableMobileMenu },
        )}>
          <Suspense fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse-rise"/>
                </div>
                <h6 className="mt-5">
                  Please wait while we load all the Components examples
                  <small>Because this is a demonstration we load at once all the Components examples. This wouldn't happen in a real live app!</small>
                </h6>
              </div>
            </div>
          }>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/login"/>} />
              {routes.routes.map((route, index) => <route.route key={index} {...route}/>)}
            </Switch>
          </Suspense>
        </div>
    );
  }

  render() {
    return <ResizeDetector handleWidth render={({width}) => this.handleResize(width)}/>;
  }
}

const mapStateToProp = (state) => ({
  auth: state.auth,
  enableClosedSidebar: state.theme.enableClosedSidebar,
  enableMobileMenu: state.theme.enableMobileMenu,
});

export default withRouter(connect(mapStateToProp, {authenticateUser, updateScreenResolution})(App));
