export const actions = {
    SET_TOTAL_SITES: 'setTotalSites',
    SET_NEWEST_CREATED_SITE_ID: 'setNewestCreatedSiteId',
}



export const setTotalSites = (total) => {
    return {
        type: actions.SET_TOTAL_SITES,
        payload: total
    }
}

export const setNewestCreatedSiteId = (id) => {
    return {
        type: actions.SET_NEWEST_CREATED_SITE_ID,
        payload: id
    }
}