import {actions} from "./actions";

const INIT_STATE = {
    isAuthenticated: false
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case actions.LOGIN_USER:
            return {...action.payload};
        case actions.LOGOUT_USER:
            return INIT_STATE;
        case actions.LOGIN_FAILED:
            const loginAttempts = typeof state.loginAttempts === 'undefined' ? 1 : state.loginAttempts + 1;
            return {
                ...state,
                isAuthenticated: false,
                loginFailed: true,
                loginAttempts: loginAttempts
            };
        default: return state;
    }
}
export default reducer;